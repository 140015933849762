@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");

.text {
  margin: -0.5rem 0 -0.5rem;
  width: 100%;
  padding: 0.5rem;
  font-family: "Work Sans", sans-serif;
}

.text > p {
  margin-bottom: 0.75rem;
}

.text > p:last-child {
  margin-bottom: 0;
}

.text_center {
  text-align: center;
}

.text_large {
  font-size: 1.5rem;
  line-height: 2rem;
}

@media (min-width: 640px) {
  .text_large {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }
}

.main {
  position: relative;
  display: flex;
  min-height: 100vh;
  overflow-x: hidden;
  background-attachment: fixed;
  color: var(--text-color);
  font-family: var(--font-family);
  font-size: 13px;
  flex-direction: column;
  --button-height: 2.5rem;
  --button-margin: 0.3rem;
  --icon-width: 1.5rem;
}

@media (min-width: 640px) {
  .main {
    font-size: 16px;
    --button-height: 3rem;
    --button-margin: 0.5rem;
    --icon-width: 1.8rem;
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #2C2C3E;
}

/* .background_image {
  background-image: url('/assets/gtr.jpg');
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  position: fixed;
  top: -2.5rem;
  left: -2.5rem;
  right: -2.5rem;
  bottom: -2.5rem;
} */

.bg_container {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}

.volume-control {
  display: flex;
  position: absolute;
}


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.container {
  position: relative;
  margin: auto;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
}

@media (min-width: 500px) {
  .container {
    max-width: 500px;
  }
}

.container_component {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: center;
  padding: 0.75rem 0.75rem 0.75rem 0.75rem;
}

.profile {
  display: flex;
  flex-wrap: wrap;
  /* margin: -0.5rem; */
  padding: 1rem;
  position: relative;
  z-index: 1;
  border-radius: 15px; 
  overflow: hidden;
}

.profile::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1; 
  border-radius: 15px;
}



.volume-container {
  position: absolute; /* Position relative to the parent */
  top: 10px; /* Distance from the top */
  left: 10px; /* Distance from the left */
  z-index: 10; /* Ensure it is on top of other elements */
}

.volume-button-container {
  display: flex; /* Align items in a row */
  align-items: center; /* Center vertically */
  border: none;
}

.play-button {
  background-color: var(--volumeBackgroundcolor);
  background-image: var(--containerGradient);
  background-origin: border-box;
  color: white;
  border: none;
}

.play-button:hover {
  background-color: var(--hoverBackgroundColor);
  border: none;
  margin: 0.1rem;
  color: white;
}

.volume-slider {
  -webkit-appearance: none; /* Menghapus gaya default */
  width: 100%; /* Lebar bar */
  height: 4px; /* Tinggi bar */
  background: var(--volumeTrackColor); /* Ganti dengan warna jalur volume */
  border-radius: 5px; /* Membuat sudut melengkung */
  outline: none; /* Menghapus outline */
}

/* Gaya untuk thumb (pengatur) */
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Menghapus gaya default */
  appearance: none; /* Menghapus gaya default */
  width: 20px; /* Lebar thumb */
  height: 20px; /* Tinggi thumb */
  background: var(--volumeThumbColor); /* Ganti dengan warna thumb volume */
  border-radius: 50%; /* Membuat thumb menjadi bulat */
  cursor: pointer; /* Menampilkan kursor pointer */
}

.volume-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--volumeThumbColor);
  border-radius: 50%;
  cursor: pointer;
}

.volume-slider {
  -webkit-appearance: none; /* Menghapus gaya default */
  width: 100%; /* Lebar bar */
  height: 4px; /* Tinggi bar */
  background: var(--volumeTrackColor); /* Ganti dengan warna jalur volume */
  border-radius: 5px; /* Membuat sudut melengkung */
  outline: none; /* Menghapus outline */
}

/* Gaya untuk thumb (pengatur) */
.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Menghapus gaya default */
  appearance: none; /* Menghapus gaya default */
  width: 20px; /* Lebar thumb */
  height: 20px; /* Tinggi thumb */
  background: var(--volumeThumbColor); /* Ganti dengan warna thumb volume */
  border-radius: 50%; /* Membuat thumb menjadi bulat */
  cursor: pointer; /* Menampilkan kursor pointer */
}

.volume-slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--volumeThumbColor);
  border-radius: 50%;
  cursor: pointer;
}

.volume-slider::-ms-thumb {
  width: 20px;
  height: 20px;
  background: var(--volumeThumbColor);
  border-radius: 50%;
  cursor: pointer;
}



.picture {
  width: 7rem;
  margin: 0.75rem auto 0.75rem auto;
}

.profile_image {
  background-image: url(https://drive.google.com/thumbnail?id=19TuPX5V6gyzEbyjSFhCY3lX4sxYb8zHf);
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 0.75rem auto;
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 9999px;
}

.container_social {
  margin-top: 0.15rem;
  width: 100%;
  justify-content: center;
  display: flex;
}

.social-tree {
  margin: 0.1rem 0 0.1rem 0;
  display: flex;
  width: 70%;
  justify-content: center;
  padding: 0.1rem;
}

.social-tree a {
  display: "inline-block";
  width: 100%;
  height: "1em";
}

.social-tree svg {
  color: #ffffff !important;
}

.social_link {
  margin: 0 0.25rem 0 0.25rem;
  padding: 0.25rem;
  opacity: 1;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.social_link:hover {
  opacity: 0.8;
}

.social_link_circle {
  display: flex;
  height: 2.75rem;
  width: 2.75rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  opacity: 1;
  background-color: transparent;
  color: rgba(var(--link-color), 1);
  border-radius: 9999px;
  margin: 0.1rem;
}

.social_link_circle:hover {
  filter: brightness(1.25);
}

.social_icon {
  max-width: 70%;
  height: auto; 
  fill: white;
  stroke: none;
}


.sm\:profile_image {
  width: 7rem;
  height: 7rem;
}

.container_link {
  position: relative;
  z-index: 10;
  margin: 0.5rem;
  width: 100%;
}

.link_outer {
  width: 100%;
}

.link {
  width: 100%;
  display: flex;
  align-items: stretch;
  transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);
  overflow-wrap: break-word;
  -moz-appearance: none;
  appearance: none;
  color: rgba(var(--link-color), 1);
}

.link_circle {
  border-radius: 1.65rem;
  background-color: rgba(var(--link-background), 1);
  border-color: var(--text-color);
  border: 0.2rem solid rgba(0, 0, 0, 1);
  box-shadow: 0 0.35rem 0 rgba(0, 0, 0, 0.5);
}

@media (min-width: 640px) {
  .link_circle {
    border-radius: 2rem;
  }
}

.link_circle:hover {
  box-shadow: 0 0 0 rgba(var(--link-shadow), 1);
}

.link_circle_shadow:hover {
  transform: translateY(0.35rem);
  filter: brightness(0.9);
}

.link_icon {
  flex-shrink: 0;
  border-radius: 0.5rem;
  /* background-color: rgba(0, 0, 0, 0.1); */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: var(--button-height);
  height: var(--button-height);
  margin: var(--button-margin);
  border-radius: 9999px;
}

.link_image {
  max-width: 75%;
  height: auto;
  border-radius: 9999px;
}

.link_outer_text {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  width: 100%;
}

.link_end {
  width: var(--button-height);
  height: var(--button-height);
  margin: var(--button-margin);
  flex-shrink: 0;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: inline-block;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}

.copyright {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .copyright {
    font-size: 14px;
  }
}