.project-card {
  background-color: rgb(0, 0, 0);
  color: white;
  break-inside: avoid;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.project-card img {
  transition: all 0.3s;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.project-card:hover img {
  transform: scale(1.1);
  filter: brightness(0.3);
}

.project-card p {
  line-height: 1.5rem;
}

.card-description {
  padding: 2% 4% 5% 4%;
}

.stacks {
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
}

.tech-stack {
  border-radius: 3px;
  font-size: 1rem;
  padding: 0.4rem 0.8rem;
  color: white;
  border: 0;
  cursor: none;
}

.tech-stack:hover {
  border: none;
  background-color: #cb450c;
  color: white;
}

.arrow {
  position: absolute;
  rotate: -45deg;
  font-size: 2.7rem;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  color: white;
}

.project-card:hover .arrow {
  top: 2%;
  left: 95.5%;
  opacity: 0.7;
  z-index: 55;
}

@media only screen and (-webkit-min-device-pixel-ratio: 1.3),
  only screen and (min-resolution: 125dpi) {
  .project-card p {
    font-size: 0.9rem;
  }
}

/* for mobile and tablet devices */

@media only screen and (max-width: 768px) {
  .tech-stack {
    font-size: 0.8rem;
  }
}
